exports.components = {
  "component---src-pages-bildnachweise-js": () => import("./../../../src/pages/bildnachweise.js" /* webpackChunkName: "component---src-pages-bildnachweise-js" */),
  "component---src-pages-catalog-order-organisation-slug-js": () => import("./../../../src/pages/catalog_order/{Organisation.slug}.js" /* webpackChunkName: "component---src-pages-catalog-order-organisation-slug-js" */),
  "component---src-pages-scholarship-interest-organisation-slug-js": () => import("./../../../src/pages/scholarship_interest/{Organisation.slug}.js" /* webpackChunkName: "component---src-pages-scholarship-interest-organisation-slug-js" */),
  "component---src-templates-image-page-template-js": () => import("./../../../src/templates/ImagePageTemplate.js" /* webpackChunkName: "component---src-templates-image-page-template-js" */),
  "component---src-templates-organisation-page-template-js": () => import("./../../../src/templates/OrganisationPageTemplate.js" /* webpackChunkName: "component---src-templates-organisation-page-template-js" */),
  "component---src-templates-organisations-overview-page-template-js": () => import("./../../../src/templates/OrganisationsOverviewPageTemplate.js" /* webpackChunkName: "component---src-templates-organisations-overview-page-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-start-page-template-js": () => import("./../../../src/templates/StartPageTemplate.js" /* webpackChunkName: "component---src-templates-start-page-template-js" */)
}

